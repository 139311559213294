<template>
  <div class="body-box">
    <!--    导航盒子-->
    <nav-box></nav-box>
    <el-card class="box-card">
      <!-- logo部分 -->
      <div>
        <img src="" alt=""/>
        <span>欢迎注册!</span>
      </div>

      <!-- 步骤条 -->
      <el-steps :active="activeIndex" finish-status="success" align-center>
        <el-step title="注册用户"></el-step>
        <el-step title="填写信息"></el-step>
        <el-step title="提交审核"></el-step>
      </el-steps>

      <div class="reg-box">
        <i class="el-icon-check"></i>
        <div>提交成功</div>

        <div style="margin-top: 40px">
          <el-button @click="quit">重新登录</el-button>
        </div>
      </div>
    </el-card>

    <qq-footer></qq-footer>
  </div>
</template>
<script>
import NavBox from '@/components/common/navBox'
import QqFooter from '@/components/common/qqFooter'

export default {
  components: {
    QqFooter,
    NavBox
  },
  data() {
    return {
      activeIndex: 3
    }
  },
  mounted() {
  },
  methods: {
    quit() {
      this.$store.dispatch('logout', flag => {
        this.$router.push('/index')
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-step__head.is-success {
  color: #3150E9;
}

/deep/ .el-step__title.is-success {
  color: #3150E9;
}

/deep/ .el-step__head.is-success {
  border-color: #3150E9;
}

.box {
  width: 1200px;
  margin: 20px auto;
  box-sizing: border-box;
  text-align: right;
  color: #ccc;

  > i {
    cursor: pointer;
  }
}

.el-card {
  width: 70%;
  margin: 0 auto;
}

.el-steps {
  width: 80%;
  margin: 20px auto;
}

.reg-box {
  margin-top: 80px;
  width: 100%;
  height: 500px;
  text-align: center;

  i {
    width: 160px;
    height: 160px;
    font-size: 120px;
    border-radius: 100px;
    padding-top: 15px;
    background-color: #67c23a;
    box-sizing: border-box;
    color: #fff;
  }

  div {
    font-size: 28px;
  }
}
</style>
